<template>
  <div id="integratedManagement-faultType">
    <ul>
      <li v-for="item of list" :key="item.timePoint">
        <span>{{ item.timePoint }}</span>
        <el-progress :percentage="item.value" :stroke-width="strokeWidth"></el-progress>
      </li>
    </ul>
  </div>
</template>

<script>
import {formatRatio} from "@/utils/util"
export default {
  data() {
    return {
      strokeWidth:0,
      list:[]
    }
  },
  created() {
    this.getList()
    this.$nextTick(()=>{
      this.strokeWidth=document.documentElement.clientWidth / 1920 * 16 * 0.7
    })
  },
  methods: {
    async getList(params={}){
      try{
        let res = await this.$http.post("/statistics/parking/duration", params)
        if(res.code===0){
          this.list = [
            {
              timePoint:"故障一",
              value:formatRatio(res.data.lessOneHour)
            },
            {
              timePoint:"故障二",
              value:formatRatio(res.data.oneToFourHour)
            },
            {
              timePoint:"故障三",
              value:formatRatio(res.data.fourToTwelveHour)
            },
            {
              timePoint:"故障四",
              value:formatRatio(res.data.moreThanTwelveHour)
            }
          ]
        }else{
          this.$message.error(res.msg)
        }
      }catch(err){
        this.$message.error("停车时长分析获取失败")
      }
    },
  }
}
</script>

<style lang="scss">
#integratedManagement-faultType {
  display: flex;
  align-items: center;
  &>ul{
    width: 100%;
    &>li{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 2rem;
      &:last-child{
        margin-bottom: 0;
      }
      .el-progress{
        width: calc(100% - 8rem);
        .el-progress__text{
          color: white;
        }
      }
      span{
        display: inline-block;
        width: 7rem;
        text-align: right;
        font-size: 0.9rem;
        color: white;
      }
    }
  }
}
</style>
